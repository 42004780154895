import { assertInInjectionContext, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';

/**
 * The `injectQueryParams` function allows you to access and manipulate query parameters from the current route.
 *
 * @template ReadT - The expected type of the read value.
 * @param {string} keyOrParamsTransform - The name of the query parameter to retrieve, or a transform function to apply to the query parameters object.
 * @param {QueryParamsOptions} options - Optional configuration options for the query parameter.
 * @returns {QueryParamsOptions} A `Signal` that emits the transformed value of the specified query parameter, or the entire query parameters object if no key is provided.
 *
 * @example
 * const search = injectQueryParams('search'); // returns the value of the 'search' query param
 * const search = injectQueryParams(p => p['search'] as string); // same as above but can be used with a custom transform function
 * const idParam = injectQueryParams('id', {transform: numberAttribute}); // returns the value fo the 'id' query params and transforms it into a number
 * const idParam = injectQueryParams(p => numberAttribute(p['id'])); // same as above but can be used with a custom transform function
 * const queryParams = injectQueryParams(); // returns the entire query params object
 */
function injectQueryParams(keyOrParamsTransform, options = {}) {
  assertInInjectionContext(injectQueryParams);
  const route = inject(ActivatedRoute);
  const queryParams = route.snapshot.queryParams || {};
  const {
    transform,
    initialValue
  } = options;
  if (!keyOrParamsTransform) {
    return toSignal(route.queryParams, {
      initialValue: queryParams
    });
  }
  if (typeof keyOrParamsTransform === 'function') {
    return toSignal(route.queryParams.pipe(map(keyOrParamsTransform)), {
      initialValue: keyOrParamsTransform(queryParams)
    });
  }
  const getParam = params => {
    const param = params?.[keyOrParamsTransform];
    if (!param) {
      return initialValue ?? null;
    }
    if (Array.isArray(param)) {
      if (param.length < 1) {
        return initialValue ?? null;
      }
      return transform ? transform(param[0]) : param[0];
    }
    return transform ? transform(param) : param;
  };
  return toSignal(route.queryParams.pipe(map(getParam)), {
    initialValue: getParam(queryParams)
  });
}
/**
 * The `injectQueryParams` function namespace provides additional functionality for handling array query parameters.
 */
(function (injectQueryParams) {
  /**
   * Retrieve an array query parameter with optional configuration options.
   *
   * @template ReadT - The expected type of the read value.
   * @param {string} key - The name of the array query parameter to retrieve.
   * @param {QueryParamsOptions} options - Optional configuration options for the array query parameter.
   * @returns {Signal} A `Signal` that emits an array of transformed values for the specified query parameter, or `null` if it's not present.
   */
  function array(key, options = {}) {
    assertInInjectionContext(injectQueryParams.array);
    const route = inject(ActivatedRoute);
    const queryParams = route.snapshot.queryParams || {};
    const {
      transform,
      initialValue
    } = options;
    const transformParam = param => {
      if (!param) {
        return initialValue ?? null;
      }
      if (Array.isArray(param)) {
        if (param.length < 1) {
          return initialValue ?? null;
        }
        // Avoid passing the transform function directly into the map function,
        // because transform may inadvertently use the array index as its second argument.
        // Typically, map provides the array index as the second argument to its callback,
        // which can conflict with transform functions like numberAttribute that expect a fallbackValue as their second parameter.
        // This mismatch can lead to unexpected behavior, such as values being erroneously converted to array indices
        // instead of NaN (which would be correct)
        return transform ? param.map(it => transform(it)) : param;
      }
      return [transform ? transform(param) : param];
    };
    const getParam = params => {
      const param = params?.[key];
      return transformParam(param);
    };
    return toSignal(route.queryParams.pipe(map(getParam)), {
      initialValue: getParam(queryParams)
    });
  }
  injectQueryParams.array = array;
})(injectQueryParams || (injectQueryParams = {}));

/**
 * Generated bundle index. Do not edit.
 */

export { injectQueryParams };
